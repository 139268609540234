import { Box, Grid } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import Loading from '../loading/loading';
import { useParams } from 'react-router-dom';
import {
    GET_GENERATED_REPORT,
    GET_ITEM_URL,
} from '../../graphql/queries/reports';
import { useForm } from 'react-hook-form';
import { useUIControlContext } from '../../context/ui-control-context';
import { GeneratedReport } from '../../types/reports';
import { IFormInputs, ItemUrlData } from '../../types/shared';
import { useDataProcessingContext } from '../../context/data-processing-context';
import { columns } from '../../data/columns/report-detail';
import CustomTable from '../../components/custom-table/custom-table';
import ErrorPage from '../error/error-page';
import Toast from '../../components/toast/toast';
import SimpleBackdrop from '../../components/backdrop/backdrop';
import ReturnButton from '../../components/return-button/return-button';
import TextInput from '../../components/text-input/text-input';

function ReportDetail() {
    document.title = `AppHub - Dqr - Generation Detail`;
    const [GetItemUrl, { data: getItemUrlData, refetch }] = useLazyQuery(
        GET_ITEM_URL,
        {
            onCompleted: (data) => {
                setItemUrlData(data.generationItemUrl);
                handleCloseBackdrop();
                handleManageToast(
                    'success',
                    'File downloaded successfully',
                    data.generationItemUrl[0]?.value
                );
            },
        }
    );
    const { control, watch } = useForm<IFormInputs>({
        defaultValues: {
            addEmail: '',
            searchValue: '',
        },
    });

    const searchValue = watch('searchValue');
    const {
        selected,
        setSelected,
        handleGetIdAndType,
        groupId,
        groupType,
        handleChipStatus,
    } = useDataProcessingContext();

    const {
        alertSeverity,
        alertMessage,
        openToast,
        handleCloseToast,
        openBackdrop,
        handleOpenBackdrop,
        handleCloseBackdrop,
        handleNavigateToProviderList,
        handleManageToast,
    } = useUIControlContext();
    const { reportId } = useParams();

    const { data, loading } = useQuery(GET_GENERATED_REPORT, {
        variables: { generationId: groupId },
    });

    const [singleReportData, setSingleReportData] = useState<GeneratedReport[]>(
        []
    );
    const [filteredSingleReport, setFilteredSingleReport] = useState<
        GeneratedReport[]
    >([]);

    const [itemUrlData, setItemUrlData] = useState<ItemUrlData[]>([]);

    const handleGetItemUrl = useCallback(
        async (id: string) => {
            if (id) {
                handleOpenBackdrop();
                try {
                    await GetItemUrl({
                        variables: {
                            generationItemId: id,
                        },
                    });
                    refetch();
                } catch (error) {
                    handleManageToast(
                        'error',
                        'There was an unexpected error trying to download the file'
                    );
                }
            }
        },
        [GetItemUrl, handleOpenBackdrop, refetch, handleManageToast]
    );

    const filterReports = useCallback(() => {
        if (!searchValue) {
            setFilteredSingleReport(singleReportData);
            return;
        }
        const filteredData = singleReportData.filter((item) => {
            return (
                item.id
                    .toString()
                    .toLowerCase()
                    .includes(searchValue.toLowerCase().replace(/\s+/g, '')) ||
                item.providerId
                    .toString()
                    .replace(/\s+/g, '')
                    .includes(searchValue) ||
                item.reportType
                    .toString()
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(searchValue.toLowerCase().replace(/\s+/g, '')) ||
                item.status
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(searchValue.toLowerCase().replace(/\s+/g, '')) ||
                item.providerName
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(searchValue.toLowerCase().replace(/\s+/g, ''))
            );
        });

        setFilteredSingleReport(filteredData);
    }, [searchValue, singleReportData]);

    const rows = useMemo(() => {
        return filteredSingleReport?.map(
            ({ id, providerId, reportType, status, providerName }) => {
                return {
                    id: id,
                    providerId: providerId,
                    reportType: reportType,
                    status: status,
                    providerName: providerName,
                };
            }
        );
    }, [filteredSingleReport]);

    const actionsData = useMemo(
        () => [
            {
                status: 'NoResults',
                color: 'warning',
                buttonText: 'send',
                actionType: 'reportDetailSendAction',
                actionEvent: (
                    reportDetailId: string,
                    groupId?: string,
                    groupType?: string,
                    providerName?: string
                ): void =>
                    handleNavigateToProviderList(
                        groupId as string,
                        groupType as string,
                        providerName,
                        reportDetailId
                    ),
            },

            {
                status: 'NoResults',
                color: 'success',
                buttonText: 'download',
                actionType: 'reportDetailDownloadAction',
                actionEvent: async (reportDetailId: string): Promise<void> => {
                    await handleGetItemUrl(reportDetailId);
                },
            },
        ],
        [handleNavigateToProviderList, handleGetItemUrl]
    );

    useEffect(() => {
        if (getItemUrlData) {
            setItemUrlData(getItemUrlData.generationItemUrl);
            handleCloseBackdrop();
        }
    }, [getItemUrlData, handleCloseBackdrop, handleManageToast]);

    useEffect(() => {
        if (itemUrlData[0]?.value) {
            handleManageToast(
                'success',
                'File downloaded successfully',
                itemUrlData[0]?.value
            );
        }
    }, [itemUrlData, handleManageToast]);

    useEffect(() => {
        try {
            if (data) {
                setSingleReportData(
                    data.reportGenerationItemResultByGenerationId
                );
            }
        } catch (error) {
            console.log('error-getting-report-detail', error);
        }
    }, [data]);

    useEffect(() => {
        filterReports();
    }, [filterReports]);

    useEffect(() => {
        if (reportId) {
            handleGetIdAndType(reportId);
        }
    }, [handleGetIdAndType, reportId]);

    if (loading) return <Loading />;

    return (
        <Box component={'article'} sx={{ width: '100%' }}>
            <Grid container gap={2} flexDirection='column'>
                <Grid item>
                    <ReturnButton />
                </Grid>

                {singleReportData.length > 0 ? (
                    <>
                        <Grid item container gap={2} alignItems='center'>
                            <Grid
                                item
                                sx={{
                                    width: '100%',
                                    marginBottom: -3,
                                    marginTop: 2,
                                }}
                            >
                                <TextInput
                                    name='searchValue'
                                    control={control}
                                    labelName={'Search by any propertie'}
                                />
                            </Grid>

                            {filteredSingleReport &&
                                filteredSingleReport.length > 0 && (
                                    <>
                                        <Grid item sx={{ width: '100%' }}>
                                            <CustomTable
                                                useCheckbox={false}
                                                selected={selected}
                                                setSelected={setSelected}
                                                columns={columns}
                                                rows={rows}
                                                handleChipStatus={
                                                    handleChipStatus
                                                }
                                                groupType={groupType}
                                                groupId={groupId}
                                                actionsData={actionsData}
                                            />
                                        </Grid>
                                    </>
                                )}
                        </Grid>

                        {filteredSingleReport?.length === 0 && (
                            <ErrorPage
                                errorMessage={'There are no reports to show'}
                            />
                        )}
                    </>
                ) : (
                    <ErrorPage errorMessage={'There are no reports to show'} />
                )}
            </Grid>
            <Toast
                handleCloseToast={handleCloseToast as () => void}
                openToast={openToast as boolean}
                severity={alertSeverity}
                message={alertMessage}
            />

            <SimpleBackdrop
                handleCloseBackdrop={handleCloseBackdrop as () => void}
                openBackdrop={openBackdrop as boolean}
            />
        </Box>
    );
}

export default ReportDetail;
