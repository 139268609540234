import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UIControlContextProvider } from './context/ui-control-context';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import { BrowserRouter } from 'react-router-dom';
import { DataProcessingContextProvider } from './context/data-processing-context';
import { SharedDataContextProvider } from './context/shared-data-context';
import { Translation } from './types/shared';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const noop = () => {
    // No-op: Function intentionally left blank
};

root.render(
    <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
                <UIControlContextProvider
                    alertSeverity={undefined}
                    alertMessage={undefined}
                    setAlertMessage={() => 'Success'}
                    errorMessage={undefined}
                    setErrorMessage={() => 'Success'}
                    openToast={undefined}
                    setOpenToast={() => false}
                    handleCloseToast={noop}

                    openBackdrop={undefined}
                    setOpenBackdrop={() => false}
                    handleOpenBackdrop={noop}
                    handleCloseBackdrop={noop}
                    handleLogOut={noop}
                    handleNavigateToProviderList={noop}
                    groupNameUnique={undefined}
                    reportDetailId={undefined}
                    isDialogOpen={undefined}
                    setIsDialogOpen={noop}
                    handleOpenDialog={noop}
                    handleCloseDialog={noop}
                    handleManageToast={noop}
                    loading={undefined}
                    setLoading={() => false}
                    refetchData={undefined}
                    setRefetchData={() => false}
                >
                    <DataProcessingContextProvider
                        transformInUnixValue={noop}
                        selected={undefined}
                        setSelected={noop}
                        handleGetIdAndType={noop}
                        groupId={undefined}
                        typeId={null}
                        groupType={undefined}
                        handleChipStatus={(status = 'Ready') => {
                            return { label: status, color: 'info' };
                        }}
                    >
                        <SharedDataContextProvider
                            newId={''}
                            peerGroupData={[]}
                            setPeerGroupData={noop}
                            peerGroupNames={[]}
                            setPeerGroupNames={noop}
                            afbStatusData={[]}
                            setAfbStatusData={noop}
                            afbStatusNames={[]}
                            setAfbStatusNames={noop}
                            handleTransformBooleanValue={(
                                value: boolean
                            ) => {
                                if (value) return 'Yes';
                            }}
                            handleTransformToBooleanValue={(
                                value: string
                            ) => {
                                if (value) return true;
                            }}
                            handleGetLanguageIdFromSelect={(
                                language: string
                            ) => {
                                if (language === 'English') return 1;
                            }}
                            handleGetLanguageItem={(
                                translations: Translation[],
                                languageId: number
                            ) => {
                                return translations.find(
                                    (item) =>
                                        item.language_Id === languageId
                                );
                            }}
                            handleGetPeerGroup={noop}
                            handleGetAfbStatusGroup={noop}
                            baseUrl=''
                            handleCreateEntity={noop}
                            handleCreateFund={noop}
                            handleGetLanguages={noop}
                            languagesData={[]}
                        >
                            <App />
                        </SharedDataContextProvider>
                    </DataProcessingContextProvider>
                </UIControlContextProvider>
            </BrowserRouter>
        </LocalizationProvider>
    </ThemeProvider>
);
reportWebVitals();